*, ::after, ::before {
	box-sizing: border-box;
}

:root {
	background: #fffff8;
	color: #11111a;
	font: normal 1em/1.42 system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
	height: 100%;
}

body {
	font-size: 1.125em;
	margin: 0;
	text-align: start;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* RESETS */

button {
	background: none;
    border: 0;
    border-radius: 0;
    display: inline-block;
    font: inherit;
    margin: 0;
    padding: 0;
}

/* THEME */
:root {
	--sun-gradient-0: hsl(55deg, 100%, 50%);
	--sun-gradient-100: hsl(51deg, 100%, 50%);
	--sun: url(#sun-gradient) hsl(51deg, 100%, 50%);
	--moon: hsl(0deg, 0%, 75%);
	--star: hsl(0deg, 0%, 75%);
	--cloud: hsl(203deg, 92%, 75%);
	--rain: hsl(200deg, 89%, 47%);
	--snow: hsl(0deg, 0%, 85%);
}

/* SHAME */
body {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100%;
}

#root {
	height: 100%;
	width: 100%;
}